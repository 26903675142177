import React from "react";
import Navbar from "../../components/shared/Navbar/Navbar";
import Sidebar from "../../components/shared/Sidebar/Sidebar";
// import Error403 from "../Error403/Error403";
import AgregarUser from "./AgregarUser";

const Usuarios = () => {
  // const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("isLogedIn")));
  // const [rol, setrol] = useState();
  // const [loading, setloading] = useState(true);
  // const [option, setoption] = useState("Agregar");

  // useEffect(() => {
  //   if (localStorage.getItem("rol") !== null && isLoggedIn === true) {
  //     let rol_ls = new Buffer(localStorage.getItem("rol"), "base64");
  //     let rol_str = rol_ls.toString("ascii");
  //     setrol(rol_str);
  //     setloading(false);
  //   } else {
  //     setloading(false);
  //   }
  // }, [isLoggedIn]);

  return (
    <>
      {/* {loading === false ? ( */}
      <>
        <div className="wrapper w-100 d-flex align-items-stretch">
          <Sidebar />
          <div id="content" className="main-cnt">
            <div className="container-fluid main-ct h-100 w-100 ">
              <Navbar />
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="h-100 w-100 d-flex flex-column">
                    <div className="mx-auto">
                      {/* <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item me-2" role="presentation">
                            <button
                              className="nav-link nl-primary active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                              onClick={() => {
                                setoption("Agregar");
                              }}
                            >
                              <i className="fa-solid fa-circle-plus fa-xl"></i>
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link nl-primary"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                              onClick={() => {
                                setoption("Editar");
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square fa-xl"></i>
                            </button>
                          </li>
                        </ul> */}

                      {/* <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          > */}
                      <AgregarUser />
                      {/* </div> */}
                      {/* <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                          >
                            <EditarUser />
                          </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* ) : (
        <div className="vh-100 d-flex">
          <div className="d-flex justify-content-cente mx-auto my-auto ">
            <div
              className="spinner-border"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            ></div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Usuarios;
