import React, { useState, useEffect } from "react";
import "../Modal.css";
import ReactModal from "react-modal";
import Select from "react-select";
import Button from "../../shared/Button/Button";
import axios from "axios";
import Cookies from "universal-cookie";
import { correos_copia } from "../../../utils/correosCopia";

const ModalAsignar = ({ open, close, qty, asignar }) => {
  const cookies = new Cookies();
  const [openM, setOpen] = useState();
  const [aliados, setAliados] = useState();
  const [search, setSearch] = useState({ cod: "", nombre: "", mail: "" });
  const [arrayID, setarrayID] = useState([]);
  const [sent, setsent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [errorMail, setErrorMail] = useState(false);
  const [tkn] = useState(cookies.get("tkn"));

  useEffect(() => {
    setOpen(open);

    const getData = () => {
      if (aliados === undefined) {
        axios
          .get(
            `${process.env.REACT_APP_API}${process.env.REACT_APP_ALIADOS_ACTV}`,
            {
              headers: {
                Authorization: `Bearer ${tkn}`,
              },
            }
          )
          .then((res) => {
            const p = res.data;

            var data_ord = p.sort((a, b) =>
              a.descripcion.localeCompare(b.descripcion)
            );

            var list = [];

            if (asignar !== "CP") {
              list.unshift({
                label: "ZONA AIRTEK",
                value: { cod: "", email: "" },
              });

              setSearch({ cod: "", nombre: "ZONA AIRTEK", mail: "" });
            }

            data_ord.forEach((obj) => {
              // get selected option
              list.push({
                label: obj.descripcion,
                value: { cod: obj.codigo_aliado, email: obj.email },
              });
            });

            setAliados(list);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    getData();

    // create empty array
    var list_id = [];

    // push each id to the array

    if (asignar === "CP") {
      qty.forEach((obj) => {
        list_id.push(obj.ID);
      });
    } else {
      qty.forEach((obj) => {
        list_id.push(obj.Id);
      });
    }

    setarrayID(list_id);

    // get email from LocalStorage
    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");
    setUser(base64ToStringNew);
  }, [open, qty, asignar, aliados, tkn]);

  // send info to API

  function sendCP() {
    setIsLoading(true);

    // create empty array
    var data = [];

    // create a new object per record,  push it into the array
    arrayID.forEach((obj) => {
      data.push({
        ID: obj,
        cod_ac: `${search.cod}`,
        nom_ac: `${search.nombre}`,
        email: `${search.email}`,
      });
    });

    // cambiar nombre de keys
    const ListaClientes = qty.map(
      ({
        REGISTRADO: FchSolicitud,
        CI_RIF: Ced_Rif,
        UBICACION_CP: Ubicacion_Cliente,
        NAP: Nombre_Nap,
        ...rest
      }) => ({
        FchSolicitud,
        Ced_Rif,
        Ubicacion_Cliente,
        Nombre_Nap,
        ...rest,
      })
    );

    // set mail content
    var mail_body = {
      Destinatario: data[0].nom_ac,
      Email_Destinatario: data[0].email,
      Asunto: "Asignación de Clientes Potenciales🚀",
      CopiaOculta: correos_copia,
      ClientePotencial: ListaClientes,
    };

    // funcion enviar correo
    async function sendMail() {
      axios
        .post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ASIGNAR_CP_EMAIL}`,
          mail_body,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then(function (response) {
          setIsLoading(false);
          if (response.status === 204) {
            setsent(true);
            setTimeout(() => {
              window.location.reload();
            }, 1200);
          }
          if (response.status === 400) {
            setsent(false);
            setErrorMail(true);
          }
        })
        .catch(function (error) {
          setsent(false);
          setErrorMail(true);
          console.error("Error:", error);
        });
    }

    // asignar clientes potenciales al aliado
    (async function () {
      Promise.all(
        data.map((id) =>
          fetch(
            `${process.env.REACT_APP_API}${process.env.REACT_APP_ASIGNAR_CP}`,
            {
              method: "PATCH",
              body: JSON.stringify({
                id: `${id.ID}`,
                cod_ac: `${id.cod_ac}`,
                nom_ac: `${id.nom_ac}`,
                usuario: `${user}`,
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tkn}`,
              },
            }
          )
        )
      )

        .then(async (responses) => {
          // status de cada request
          var resp = [];
          responses.forEach((result) => {
            resp.push(result.status);
          });

          // si todas son success, enviar email
          if (resp.every((e) => e === 204) === true) {
           await sendMail();
          } else {
            setsent(false);
            throw new Error("Email");
          }
        })
        .catch((error) => {
          setsent(false);
          console.log(error);
        });
    })();
  }

  function sendMDT() {
    setIsLoading(true);

    // create empty array
    var data = [];

    // create a new object per record,  push it into the array
    arrayID.forEach((obj) => {
      data.push({
        ID: obj,
        Codac_reasignado: `${search.cod}`,
        Ac_reasignado: `${search.nombre}`,
        user: `${user}`,
      });
    });

    (async function () {
      Promise.all(
        data.map((id) =>
          fetch(
            `${process.env.REACT_APP_API}${process.env.REACT_APP_REASIGNAR_MDT}${id.ID}`,
            {
              method: "PATCH",
              body: JSON.stringify({
                Codac_reasignado: `${id.Codac_reasignado}`,
                Ac_reasignado: `${id.Ac_reasignado}`,
                user: `${id.user}`,
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tkn}`,
              },
            }
          )
        )
      )

        .then(async (responses) => {
          // status de cada request
          var resp = [];
          responses.forEach((result) => {
            resp.push(result.status);
          });

          // si todas son success, enviar status
          if (resp.every((e) => e === 204) === true) {
            setsent(true);
            setTimeout(() => {
              window.location.reload();
            }, 1200);
          } else {
            setsent(false);
            throw new Error("MDT no asignado");
          }
        })
        .catch((error) => {
          setsent(false);
          console.log(error);
        });
    })();
  }

  return (
    <ReactModal
      onRequestClose={close}
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered"
      tabIndex="-1"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content modal1">
          <div className="modal-header mhdr">
            <button
              type="button"
              className="btn-close close-modal"
              onClick={close}
            ></button>
          </div>

          <div className="modal-body mbdy">
            {qty.length !== 0 && sent === null && (
              <div className="text-center d-flex flex-column">
                <div className="d-flex flex-column mx-auto align-items-center">
                  {qty.length === 1 ? (
                    <div>
                      <div className="d-flex justify-content-center">
                        <span className="me-2 fw-bold oportunidades_t">
                          #{qty.length}
                        </span>
                        <h6 className="oportunidades_t mb-0">
                          {asignar === "CP" ? "Oportunidad" : "Registro"}
                        </h6>
                      </div>
                      <p>
                        {asignar === "CP" ? "seleccionada" : "seleccionado"}{" "}
                        para enviar
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="d-flex">
                        <span className="me-2 fw-bold oportunidades_t">
                          #{qty.length}
                        </span>
                        <h6 className="oportunidades_t mb-0">
                          {asignar === "CP" ? "Oportunidades" : "Registros"}
                        </h6>
                      </div>
                      <p>
                        {asignar === "CP" ? "seleccionadas" : "seleccionados"}{" "}
                        para enviar
                      </p>
                    </div>
                  )}
                </div>
                <div className="mt-2 mb-4">
                  <Select
                    onChange={(e) => {
                      if (e !== null) {
                        setSearch({
                          cod: e.value.cod,
                          nombre: e.label,
                          email: e.value.email,
                        });
                      } else {
                        setSearch({
                          cod: "",
                          nombre: "",
                          email: "",
                        });
                      }
                    }}
                    value={search.nombre.value}
                    isSearchable={true}
                    defaultValue={
                      asignar === "CP"
                        ? null
                        : {
                            label: "ZONA AIRTEK",
                            value: { cod: "", email: "" },
                          }
                    }
                    placeholder={"Seleccionar Aliado Comercial"}
                    options={aliados}
                    isClearable
                  />
                </div>
                <div className="mt-3 mb-2">
                  <Button
                    text={"Enviar"}
                    myClass={`btn btn-primary  w-100  ${
                      asignar === "CP" &&
                      Object.values(search).every(
                        (x) => x === null || x === ""
                      ) === true
                        ? "btn-disabled"
                        : ""
                    }`}
                    funct={asignar === "CP" ? sendCP : sendMDT}
                    loading={isLoading}
                    loadingMsg={"Enviando"}
                  />
                </div>
              </div>
            )}

            {sent && (
              <div className="text-center">
                <i className="fa-solid fa-circle-check modal-icon-success mb-2 fa-2xl"></i>
                <h4 className="modal-success mt-3">¡Muy Bien!</h4>
                <p className="modal-error-txt">
                  {qty.length}
                  {qty.length === 1
                    ? asignar === "CP"
                      ? " Oportunidad enviada "
                      : " Registro enviado "
                    : asignar === "CP"
                    ? " Oportunidades enviadas "
                    : " Registros enviados "}
                  correctamente
                </p>
              </div>
            )}

            {sent === false && (
              <div className="text-center">
                <i className="fa-solid fa-circle-xmark mb-2 fa-2xl modal-icon-error"></i>
                <h4 className="modal-error">
                  Hubo un percance con los datos enviados.
                </h4>
                {errorMail && (
                  <p className="err-desc">
                    Se produjo un problema al enviar el correo electrónico.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ModalAsignar;
