import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/shared/Button/Button";
import Input from "../../components/shared/Input/Input";
import TextArea from "../../components/shared/TextArea/TextArea";
import Cookies from "universal-cookie";
import "./Usuarios.css";
import Modal1 from "../../components/Modal/Modal";
import userError from "../../assets/svg/userError.svg";
import userSuccess from "../../assets/svg/userSuccess.svg";

const AgregarUser = () => {
  const cookies = new Cookies();
  const [tkn] = useState(cookies.get("tkn"));
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Roles: "",
      Codigo_ac: "",
    },
  });

  const [reqstatus, setreqstatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsj, seterrorMsj] = useState(null);
  const [showPassword, setShowPassword] = useState(true);
  const [showRePassword, setShowRePassword] = useState(true);
  const [modal, setModal] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);

    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");

    data["Usergen"] = base64ToStringNew;
    data["Roles"] = "004";
    data["Codigo_ac"] = "MB2";
    data["Usuario"] = data["Correo"];

    fetch(`${process.env.REACT_APP_API}usuario/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tkn}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 400) {
          return res.json().then((errorData) => {
            seterrorMsj(errorData);
            setIsLoading(false);
            Open();
          });
        } else {
          setreqstatus(res.status);
          reset();
          seterrorMsj(null);
          setIsLoading(false);
          Open();
        }
      })

      .catch((error) => {
        setIsLoading(false);
        reset();
        console.error("Error:", error);
        seterrorMsj("Error al crear usuario");
      });
  };

  const Open = () => {
    setModal(!modal);
  };

  return (
    <div
      className="mx-auto"
      style={{
        height: "100%",
        width: "400px",
      }}
    >
      <div
        className="px-5"
        style={{
          height: "100%",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <div className="d-flex flex-column">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#111",
              marginBottom: "15px",
              fontFamily: "Montserrat",
              textAlign: "center",
            }}
          >
            Crear usuario
          </p>
        </div>

        <div>
          <form>
            <div className="d-flex flex-row gap-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20 21C20 18.8783 19.1571 16.8434 17.6569 15.3431C16.1566 13.8429 14.1217 13 12 13C9.87827 13 7.84344 13.8429 6.34315 15.3431C4.84285 16.8434 4 18.8783 4 21"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <p className="fw-bold" style={{ fontSize: "14px" }}>
                Información personal
              </p>
            </div>

            <div className="mb-3 w-100">
              <Input
                fieldName="Nombre"
                register={register}
                errors={errors}
                placeHolder="Nombre"
                isRequired={true}
                maximLength={25}
                minimLength={3}
              />
            </div>

            <div className="mb-3 w-100">
              <Input
                fieldName="Apellido"
                register={register}
                errors={errors}
                placeHolder=" Apellido"
                isRequired={true}
                maximLength={25}
                minimLength={3}
              />
            </div>

            <div className="d-flex aliados-wrp w-100">
              <div className="mb-3 w-100 ac-group">
                <Input
                  fieldName="Correo"
                  register={register}
                  errors={errors}
                  placeHolder="Correo"
                  isRequired={true}
                  isEmail={true}
                  maximLength={55}
                  minimLength={10}
                />
              </div>
            </div>

            <div className="d-flex aliados-wrp w-100">
              <div className="mb-3 w-100 ac-group">
                <Input
                  fieldName="Telefono"
                  register={register}
                  errors={errors}
                  placeHolder="Teléfono"
                  isRequired={true}
                  isPhone={true}
                  maximLength={11}
                  minimLength={11}
                />
              </div>
            </div>

            <div className="mb-3 w-100 ac-group">
              <TextArea
                fieldName="Comentario"
                register={register}
                errors={errors}
                placeHolder="Comentario"
                isRequired={false}
                maximLength={150}
                minimLength={5}
              />
            </div>

            <div className="d-flex flex-row gap-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.586 17.414C2.2109 17.789 2.00011 18.2976 2 18.828V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8947 2.73478 22 3 22H6C6.26522 22 6.51957 21.8947 6.70711 21.7071C6.89464 21.5196 7 21.2652 7 21V20C7 19.7348 7.10536 19.4804 7.29289 19.2929C7.48043 19.1054 7.73478 19 8 19H9C9.26522 19 9.51957 18.8947 9.70711 18.7071C9.89464 18.5196 10 18.2652 10 18V17C10 16.7348 10.1054 16.4804 10.2929 16.2929C10.4804 16.1054 10.7348 16 11 16H11.172C11.7024 15.9999 12.211 15.7891 12.586 15.414L13.4 14.6C14.7898 15.0842 16.3028 15.0823 17.6915 14.5948C19.0801 14.1072 20.2622 13.1628 21.0444 11.9161C21.8265 10.6694 22.1624 9.19418 21.9971 7.73175C21.8318 6.26931 21.1751 4.90626 20.1344 3.86558C19.0937 2.8249 17.7307 2.16819 16.2683 2.0029C14.8058 1.8376 13.3306 2.1735 12.0839 2.95565C10.8372 3.73779 9.89279 4.91988 9.40525 6.30853C8.91771 7.69717 8.91585 9.21017 9.4 10.6L2.586 17.414Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.5 8C16.7761 8 17 7.77614 17 7.5C17 7.22386 16.7761 7 16.5 7C16.2239 7 16 7.22386 16 7.5C16 7.77614 16.2239 8 16.5 8Z"
                  fill="black"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p className="fw-bold" style={{ fontSize: "14px" }}>
                Seguridad
              </p>
            </div>

            <div
              className="mb-3 w-100 ac-group"
              style={{ position: "relative" }}
            >
              <Input
                fieldName="Password"
                register={register}
                errors={errors}
                isPassword={showPassword}
                placeHolder="Contraseña"
                isRequired={true}
                maximLength={12}
                minimLength={8}
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top:
                    errors.Password &&
                    errors.Password.message ===
                      "La contraseña debe incluir al menos un número, un símbolo especial, una letra mayúscula y una letra minúscula."
                      ? "20%"
                      : errors.Password &&
                        errors.Password.message === "Este campo es requerido *."
                      ? "32%"
                      : (errors.Password &&
                          errors.Password.message ===
                            "Este campo debe tener como mínimo 8 caracteres.") ||
                        (errors.Password &&
                          errors.Password.message ===
                            "Este campo debe tener como máximo 12 caracteres.")
                      ? "25%"
                      : "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                aria-label="Toggle password visibility"
              >
                {showPassword ? (
                  <i className="fa-solid fa-eye text-secondary"></i>
                ) : (
                  <i className="fa-solid fa-eye-slash text-secondary"></i>
                )}
              </div>
            </div>

            <div
              className="mb-3 w-100 ac-group"
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Input
                fieldName="Re_Password"
                register={register}
                errors={errors}
                watch={watch}
                isPassword={showRePassword}
                matchField={true}
                placeHolder="Confirmar contraseña"
                isRequired={true}
                maximLength={12}
                minimLength={8}
              />
              <div
                onClick={() => setShowRePassword(!showRePassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top:
                    errors.Re_Password &&
                    errors.Re_Password.message ===
                      "La contraseña debe incluir al menos un número, un símbolo especial, una letra mayúscula y una letra minúscula."
                      ? "20%"
                      : errors.Re_Password &&
                        errors.Re_Password.message ===
                          "Este campo es requerido *."
                      ? "32%"
                      : ((errors.Re_Password &&
                          errors.Re_Password.message ===
                            "Este campo debe tener como mínimo 8 caracteres.") ||
                        (errors.Re_Password &&
                          errors.Re_Password.message ===
                            "Este campo debe tener como máximo 12 caracteres.") ||
                        (errors.Re_Password &&
                          errors.Re_Password.message ===
                            "Los campos 'Contraseña' y 'Confirmar Contraseña' no coinciden."))
                      ? "25%"
                      : "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showRePassword ? (
                  <i className="fa-solid fa-eye text-secondary"></i>
                ) : (
                  <i className="fa-solid fa-eye-slash text-secondary"></i>
                )}
              </div>
            </div>

            <div className="d-flex w-100">
              <div className="mx-auto w-80">
                <Button
                  text={"Guardar"}
                  myClass={"btn btn-save pe-4 ps-4 pt-2 pb-2 w-100"}
                  style={{ fontFamily: "Montserrat", fontWeight: "500" }}
                  funct={handleSubmit(onSubmit)}
                  loading={isLoading}
                  loadingMsg={"Guardando..."}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal1
        open={modal}
        modalType={3}
        close={() => Open()}
        svgIcon={
          errorMsj
            ? userError
            : reqstatus && reqstatus === 204
            ? userSuccess
            : userError
        }
        title_class={"modal-title-user"}
        modalTitle={
          errorMsj
            ? errorMsj
            : reqstatus && reqstatus === 204
            ? "Usuario creado exitosamente"
            : "Error al crear usuario"
        }
      />
    </div>
  );
};

export default AgregarUser;
