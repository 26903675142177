import React, { useState } from "react";
import Select from "react-select";

const Select1 = ({
  onSearch,
  data,
  placeholder,
  zeroValue,
  custom,
  styles2,
}) => {
  const [search, setSearch] = useState("");

  const onInputChange = (e) => {
    if (e !== null) {
      setSearch(e.value);
      onSearch(e.value);
    } else {
      if (zeroValue === true) {
        setSearch("0");
        onSearch("0");
      } else {
        setSearch("");
        onSearch("");
      }
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: "auto",
      minWidth: "100%",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "#ededed ",
    }),
    clearIndicator: (base) => ({
      ...base,
      color: "#bababa ",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#111",
      paddingLeft: 5,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "1em",
      color: "#111",
      opacity: 1,
      fontWeight: 400,
    }),
    control: (base, state) => ({
      ...base,
      padding: 0,
      fontSize: 11,
      opacity: 1,
      width: "auto",
      color: "#111",
      boxShadow: "none",
      borderRadius: custom ? 20 : 4,
      border:
        state.isFocused && custom
          ? "1px solid #111"
          : !state.isFocused && custom
          ? "1px solid #111"
          : state.isFocused && custom !== null
          ? "1px solid #C4C4C4"
          : "1px solid #C4C4C4",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#FFF" : "#111",
      backgroundColor: state.isFocused ? "#0066FF" : null,
      padding: 8,
      fontWeight: state.isFocused ? "600" : "",
      fontSize: 11,
    }),

    singleValue: (base) => ({
      ...base,
      padding: 6,
      width: "auto",
      borderRadius: 10,
      background: " #0066ff",
      color: "white",
      display: "flex",
    }),

    noOptionsMessage: (base) => ({
      ...base,
      fontSize: 11,
      color: "#0066FF",
      fontWeight: "600",
    }),
  };

  const customStyles2 = {
    menu: (provided) => ({
      ...provided,
      width: "auto",
      minWidth: "100%",
    }),

    clearIndicator: (base) => ({
      ...base,
      color: "#111 ",
      paddingRight: "12px",
      fontSize: "10px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#111",

      paddingLeft: 2,
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "14px",
      color: "#111",
      opacity: 1,
      fontWeight: 400,
    }),
    control: (base, state) => ({
      ...base,
      padding: 0,
      fontSize: 14,
      opacity: 1,
      width: "auto",
      color: "#111",
      boxShadow: "none",
      height: "50px",
      borderRadius: custom ? 20 : styles2 ? 10 : 4,
      border:
        state.isFocused && custom
          ? "1px solid #111"
          : !state.isFocused && custom
          ? "1px solid #111"
          : state.isFocused && custom !== null
          ? "1px solid #C4C4C4"
          : styles2
          ? "1px solid rgba(17, 17, 17, 0.6)"
          : "1px solid #C4C4C4",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#FFF" : "#111",
      backgroundColor: state.isFocused ? "#0066FF" : null,
      padding: 8,
      fontWeight: state.isFocused ? "600" : "",
      fontSize: 14,
    }),

    singleValue: (base) => ({
      ...base,
      padding: 6,
      width: "auto",
      borderRadius: 10,

      color: "#111",
      display: "flex",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "transparent",
    }),

    noOptionsMessage: (base) => ({
      ...base,
      fontSize: 14,
      color: "#0066FF",
      fontWeight: "600",
    }),
  };

  return (
    <Select
      onChange={onInputChange}
      value={search.value}
      isSearchable={true}
      placeholder={placeholder}
      options={data}
      noOptionsMessage={() => "No se han encontrado resultados"}
      styles={styles2 ? customStyles2 : customStyles}
      isClearable={styles2 ? false : true}
    />
  );
};

export default Select1;
