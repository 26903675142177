import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../../components/shared/Navbar/Navbar";
import Sidebar from "../../components/shared/Sidebar/Sidebar";
import Error403 from "../Error403/Error403";
import Pagination from "react-js-pagination";
import TableHeader from "../../components/shared/TableHeader/TableHeader";
import edit from "../../assets/svg/edit.svg";
import lock from "../../assets/svg/open-padlock.svg";
import closed_lock from "../../assets/svg/closed-padlock.svg";
import Search from "../../components/shared/SearchBar/SearchBar";
import ExportExcel from "../../utils/export/ExportExcel/ExportExcel";
import ExportPDF from "../../utils/export/ExportPDF/ExportPDF";
import arrow_down from "../../assets/svg/arrow-down.svg";
import Modal1 from "../../components/Modal/Modal";
import AgregarAliado from "./AgregarAliado";
import ModalB from "../../components/ModalB/ModalB";
import ModalC from "../../components/ModalB/ModalB";
import axios from "axios";
import Cookies from "universal-cookie";
import { getAliadoStatus } from "../../utils/functions/AC_Status";
import Loader from "../../components/Loaders/Loader";

const Aliados = () => {
  const cookies = new Cookies();
  const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("isLogedIn")));
  const [aliados, setAliados] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 7;
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [search, setSearch] = useState("");
  const [downloadExcel, setExport] = useState("");
  const [modal, setModal] = useState(false);
  const [modalAgr, setModalAgr] = useState(false);
  const [modalType, setmodalType] = useState(0);
  const [clicked_Ac, setclicked_Ac] = useState(0);
  const [modalInfo, setInfo] = useState({
    codigo_aliado: "",
    instagram: "",
    direccion: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [msj, setMsj] = useState("");
  const [icon, setIcon] = useState("");
  const [showButtons, setButtons] = useState(false);
  const [next, setNext] = useState(false);
  const [sent, setSent] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [ac_upd, setac_upd] = useState({ cod: null, n: null });
  const [modalc, setModalc] = useState(false);
  const [btn, setBtn] = useState("");
  const [tkn] = useState(cookies.get("tkn"));
  const [acComment, setacComment] = useState("");
  const [opacityBtns, setopacityBtns] = useState(true);

  useEffect(() => {
    const getData = () => {
      if (aliados === undefined) {
        let aliados_actv = [];
        let aliados_inactiv = [];

        axios
          .get(
            `${process.env.REACT_APP_API}${process.env.REACT_APP_ALIADOS_ACTV}`,
            {
              headers: {
                Authorization: `Bearer ${tkn}`,
              },
            }
          )
          .then(async (res) => {
            aliados_actv = await getAliadoStatus(res.data, 1);
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${process.env.REACT_APP_API}${process.env.REACT_APP_ALIADOS_INACTIV}`,
            {
              headers: {
                Authorization: `Bearer ${tkn}`,
              },
            }
          )
          .then(async (res) => {
            aliados_inactiv = await getAliadoStatus(res.data, 0);
            setIsLoading(false);

            if (aliados_inactiv.length > 0 && aliados_actv.length > 0) {
              var lista_Ac = [...aliados_actv, ...aliados_inactiv];

              var data_ord = lista_Ac.sort((a, b) =>
                a.descripcion.localeCompare(b.descripcion)
              );

              setAliados(data_ord);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      }
    };

    getData();
  }, [aliados, tkn]);

  // modal
  const Open = () => {
    setModal(!modal);
  };

  // modal
  const OpenMA = () => {
    setModalAgr(!modalAgr);
  };

  function editar(cod) {
    setclicked_Ac(cod);
    setmodalType(5);
    Open();
  }

  const SetModalInfo = (codigo, ig, direccion) => {
    var info_ad = {
      codigo_aliado: codigo,
      instagram: ig,
      direccion: direccion,
    };

    info_ad = Object.fromEntries(
      Object.entries(info_ad).filter(([_, v]) => v !== "")
    );

    setInfo(info_ad);
    setmodalType(2);
    Open();
  };

  const headers = [
    {
      name: "Aliado Comercial",
      field: "descripcion",
      sortable: true,
    },
    { name: "CI / RIF", field: "rif", sortable: false },
    {
      name: "Representante",
      field: "representante",
      sortable: false,
    },
    { name: "Correo Electrónico", field: "email", sortable: false },
    { name: "Teléfono", field: "telefono", sortable: false },
    { name: "Editar", field: "", sortable: false },
    { name: "Bloquear / Desbloquear", field: "", sortable: false },
    { name: "Ver Más", field: "", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = aliados;

    if (aliados && aliados.length > 0) {
      setTotalItems(computedComments.length);

      if (search !== "" && search !== null) {
        computedComments = computedComments.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(search.toLowerCase())
          )
        );
      }

      setExport(computedComments);
      //Sorting rows
      if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
          (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
      }

      //Slice current Page
      return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }
  }, [aliados, currentPage, sorting, search]);

  const cambiar = (n) => {
    if (n === 0) {
      setMsj("¿Desea desbloquear a este aliado?");
      setBtn("desbloquear");
    } else {
      setMsj("¿Desea bloquear a este aliado?");
      setBtn("bloquear");
    }

    setButtons(true);
    setIcon(`fas fa-exclamation-circle modal-icon-success fa-3x`);
    Open2();
    setNext(true);
  };

  const Openc = () => {
    setModalc(!modalc);

    if (sent === true) {
      setTimeout(() => {
        window.location.reload();
      }, 600);
    }
  };

  const Open2 = () => {
    setModal2(!modal2);
  };

  function updown(cod, n) {
    cambiar();

    if (next) {
      setTimeout(() => {
        Open2();
      }, 660);

      var cambio_status;

      if (n === 0) {
        cambio_status = 1;
      } else {
        cambio_status = 0;
      }

      let buff = new Buffer(localStorage.getItem("username"), "base64");
      let userL = buff.toString("ascii");

      axios
        .patch(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_EDITAR_AC}${process.env.REACT_APP_UP_DOWN_AC}`,
          {
            id: `${cod}`,
            accion: `${cambio_status}`,
            comentario: `${acComment}`,
            usuario: `${userL}`,
          },
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then(function (response) {
          setIsLoading(false);

          if (response.status === 204) {
            if (n === 0) {
              setSent(true);
              setIcon(`fas fa-check-circle modal-icon-success fa-3x`);
              setMsj("El Aliado se ha desbloqueado exitosamente.");
              Openc();
            } else {
              setSent(true);
              setIcon(`fas fa-check-circle modal-icon-success fa-3x`);
              setMsj("El Aliado se ha bloqueado exitosamente.");
              Openc();
            }
          } else {
            setIcon(`fa-solid fa-circle-xmark modal-icon-error fa-3x`);
            setNext(false);
            Openc();
            setMsj("Se produjo un error. Vuelve a intentarlo más tarde.");
          }
        })
        .catch(function (error) {
          setIcon(`fa-solid fa-circle-xmark modal-icon-error fa-3x`);
          setNext(false);
          Openc();
          setMsj("Se produjo un error. Vuelve a intentarlo más tarde.");
        });
    }
  }

  return (
    <>
      {isLoggedIn === true ? (
        <div className="wrapper w-100 d-flex align-items-stretch">
          <Sidebar />
          <div id="content" className="main-cnt">
            <div className="container-fluid main-ct w-100 ">
              <Navbar />

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="p-4">
                    <div className="mb-4 d-flex aliados-wrp justify-content-between align-items-center">
                      <p className="mb-0 aliados-c">Aliados Comerciales</p>
                    </div>
                    {isLoading === true ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="d-flex justify-content-between mb-4 aliados-sb">
                          <div
                            className="d-flex me-2 search-bar-tableb"
                            style={{ width: "286px" }}
                          >
                            <div className="dropdown me-1 ">
                              <button
                                className="btn btn-primary dropdown-toggle download-btn ps-1 pe-1 pt-1 pb-1"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={arrow_down} alt="ícono flecha" />
                              </button>
                              <ul
                                className="dropdown-menu drpdwn-export"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="pdf-list">
                                  <ExportExcel data={downloadExcel} rep_t={3} />
                                </li>

                                <li className="pdf-list">
                                  <ExportPDF data={downloadExcel} rep_t={3} />
                                </li>
                              </ul>
                            </div>

                            <Search
                              onSearch={(value) => {
                                setSearch(value);
                                setCurrentPage(1);
                              }}
                              placeholder={"Buscar Por Nombre del Aliado"}
                            />
                          </div>

                          <button
                            className="btn btn-save pe-4 ps-4"
                            onClick={() => OpenMA()}
                          >
                            <i className="fa-thin fa-plus me-2 fa-2xl"></i>
                            Agregar Aliado
                          </button>
                        </div>

                        <div className="table-responsive">
                          <table className="table table-b table-bordered">
                            <TableHeader
                              headers={headers}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                              id={"selectAll"}
                              type={"checkbox"}
                            />
                            <tbody>
                              {commentsData &&
                                commentsData.map((comment) => (
                                  <tr key={comment.codigo_aliado}>
                                    <td>{comment.descripcion}</td>
                                    <td>{comment.rif}</td>
                                    <td>{comment.representante}</td>

                                    <td>{comment.email}</td>
                                    <td>{comment.telefono}</td>

                                    <td>
                                      <button
                                        className="btn"
                                        onClick={() =>
                                          editar(comment.codigo_aliado)
                                        }
                                        style={{
                                          backgroundColor: "#0066FF",
                                          border: "1px solid #cdcdcd",
                                          color: "#111 !important",
                                          fontSize: "11px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        <img
                                          src={edit}
                                          alt="editar"
                                          width={18}
                                          height={19}
                                        />
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className="btn"
                                        style={{
                                          backgroundColor:
                                            comment.estatus === 0
                                              ? "#F55B5D"
                                              : "#4BDE97",
                                          color: "#FFF",
                                          fontSize: "11px",
                                          borderRadius: "8px",
                                        }}
                                        onClick={() => {
                                          setac_upd({
                                            cod: comment.codigo_aliado,
                                            n: comment.estatus,
                                          });

                                          cambiar(comment.estatus);
                                        }}
                                      >
                                        <img
                                          src={
                                            comment.estatus === 0
                                              ? closed_lock
                                              : lock
                                          }
                                          alt="bloquear / desbloquear"
                                          width={20}
                                          height={19}
                                        />
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-light btn-vmas border-0"
                                        onClick={() =>
                                          SetModalInfo(
                                            comment.codigo_aliado,
                                            comment.instagram,
                                            comment.direccion
                                          )
                                        }
                                      >
                                        <i
                                          className="fa-solid fa-chevron-right"
                                          style={{ fontSize: "14px" }}
                                        ></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="row">
                          <p className="mb-3 total-reg total-reg-ac">
                            Total Registros: <span>{totalItems}</span>
                          </p>
                        </div>

                        <div className="d-flex">
                          <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={ITEMS_PER_PAGE}
                            totalItemsCount={totalItems}
                            disabledClass={"disabled-page"}
                            onChange={(page) => setCurrentPage(page)}
                            itemClass="page-item"
                            innerClass="pagination pag-ac"
                            linkClass="page-link"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Error403 />
      )}

      <Modal1
        open={modal}
        modalType={modalType}
        close={() => Open()}
        cod={clicked_Ac}
        uInfo={modalInfo}
      />

      <ModalB
        open={modal2}
        msj={msj}
        close={Open2}
        txt={
          <textarea
            className="ac-comment"
            placeholder="Comentario..."
            minLength={1}
            maxLength={100}
            onChange={(e) => {
              var valueInput = e.target.value.trim();
              if (valueInput.length > 1) {
                setacComment(valueInput);
                setopacityBtns(false);
              } else {
                setopacityBtns(true);
              }
            }}
          ></textarea>
        }
        btn1={btn}
        icon={icon}
        buttons={showButtons}
        funct={() => updown(ac_upd.cod, ac_upd.n)}
        funct2={Open2}
        opacity={opacityBtns}
      />

      <ModalC open={modalc} msj={msj} close={Openc} icon={icon} />

      <AgregarAliado open={modalAgr} close={() => OpenMA()} />
    </>
  );
};

export default Aliados;
