import React, { useEffect, useState, useMemo } from "react";
import "./TableB.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Pagination from "react-js-pagination";
import DateSelector from "../../components/DateSelector/DateSelector";
import Search from "../../components/shared/SearchBar/SearchBar";
import TableHeader from "../../components/shared/TableHeader/TableHeader";
import Select1 from "../../components/shared/Select/Select";
import Modal1 from "../../components/Modal/Modal";
import Status from "../../components/Status/Status";
import map_pin from "../../assets/svg/map-pin.svg";
import arrow_down from "../../assets/svg/arrow-down.svg";
import ExportExcel from "../../utils/export/ExportExcel/ExportExcel";
import ExportPDF from "../../utils/export/ExportPDF/ExportPDF";
import ModalAsignar from "../../components/Modal/ModalAsignar/ModalAsignar";
import CardTabs from "../CardTabs/CardTabs";
import axios from "axios";
import Cookies from "universal-cookie";
import { formatArrCP } from "../../utils/functions/formatArrCP";
import AsignarCP from "../../utils/AsignarCP/AsignarCP";
import Loader from "../../components/Loaders/Loader";
import ModalStep from "../../components/Modal/ModalStep/ModalStep";
import emptyData from "../../assets/img/empty.png";

const TableB = () => {
  const [comments, setComments] = useState([]);
  const [asignar_a, setAsig] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();

  const [multipleSearch, setMultipleSearch] = useState({
    aliado: "",
    MDT: "",
    nodo: "",
    estatus: "",
    plan: "",
    NAP: "",
  });

  const [dateFilter, setdateFilter] = useState("");
  const [downloadExcel, setExport] = useState("");
  const ITEMS_PER_PAGE = 6;
  const now = new Date();
  const yesterdayBegin = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1
  );

  const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const [value, onChange] = useState([yesterdayBegin, todayEnd]);
  const [modal, setModal] = useState(false);
  const [modalAC, setModalAC] = useState(false);
  const [modalStepM, setmodalStep] = useState(false);
  const [qtyNN, setQtyNN] = useState(0);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [modalInfo, setInfo] = useState({
    plan: "",
    nodo: "",
    nap: "",
    direccion: "",
  });
  const [modalType, setmodalType] = useState(0);
  const [coords, setCords] = useState({ lat: "", lng: "" });
  const [tkn] = useState(cookies.get("tkn"));
  const [step, setstep] = useState(0);
  const [toast, setToast] = useState(false);
  const headers = [
    { name: "", field: "", sortable: false, check: true },
    { name: "Registrado", field: "REGISTRADO", sortable: true },
    { name: "C.I", field: "CI_RIF", sortable: false },
    { name: "Nombre", field: "NOMBRE", sortable: true },

    { name: "Teléfono", field: "TELEFONO", sortable: false },
    { name: "Correo Electrónico", field: "CORREO", sortable: false },
    { name: "Aliado", field: "MDT_AC", sortable: false },
    { name: "Coorde...", field: "UBICACION_CP", sortable: false },
    { name: "MDT", field: "MDT", sortable: false },
    { name: "Estatus", field: "ESTATUS_MDT", sortable: true },
    { name: "Ver más", field: "", sortable: false },
  ];

  useEffect(() => {
    const getData = () => {
      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_CPOTENCIALES}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then(async (res) => {
          setAsig(res.data);
          const p = await formatArrCP(res.data);
          setIsLoading(false);
          setComments(p);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    };

    getData();
  }, [tkn]);

  // modal
  const Open = () => {
    setModal(!modal);
  };

  const OpenAC = () => {
    setModalAC(!modalAC);
  };

  const OpenMS = () => {
    setmodalStep(!modalStepM);
  };

  const SetModalInfo = (plan, nodo, nap, direccion) => {
    setInfo({
      plan: plan,
      nodo: nodo,
      nap: nap,
      direccion: direccion,
    });
    setmodalType(2);
    Open();
  };

  const asignar = () => {
    OpenAC();
  };

  const pausar = () => {
    OpenMS();
    setstep(5);
  };

  const cancelar = () => {
    OpenMS();
    setstep(8);
  };

  // select all checkboxes | based on filter response

  const handleSelectAll = (e) => {
    if (
      search === "" &&
      qtyNN === 0 &&
      Object.values(multipleSearch).every((x) => x === null || x === "") ===
        true
    ) {
      setIsCheckAll(!isCheckAll);
      setCheckedCheckboxes(comments.map((li) => li));
    } else {
      setIsCheckAll(!isCheckAll);
      setCheckedCheckboxes(downloadExcel.map((li) => li));
    }
    if (isCheckAll) {
      setCheckedCheckboxes([]);
    }
  };

  // select individual checkboxes
  const handleCheckboxChange = (comment) => {
    const isChecked = checkedCheckboxes.some(
      (checkedCheckbox) => checkedCheckbox.ID === comment.ID
    );
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter(
          (checkedCheckbox) => checkedCheckbox.ID !== comment.ID
        )
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(comment));
    }
  };

  // plan
  const nap_1 = useMemo(
    () =>
      [...new Set(comments.map((item) => item.NAP))].map((type) => {
        return { label: type, value: type };
      }),

    [comments]
  );

  // nodo
  const test = useMemo(
    () =>
      [...new Set(comments.map((item) => item.NODO))].map((type) => {
        return { label: type, value: type };
      }),

    [comments]
  );

  // plan
  const plan_1 = useMemo(
    () =>
      [...new Set(comments.map((item) => item.PLAN_SOLICITADO))].map((type) => {
        return { label: type, value: type };
      }),

    [comments]
  );

  // status
  const options = useMemo(
    () =>
      [...new Set(comments.map((item) => item.ESTATUS_MDT))].map((type) => {
        return { label: type, value: type };
      }),

    [comments]
  );

  // mdt
  const mdt = useMemo(
    () =>
      [...new Set(comments.map((item) => item.MDT))].map((type) => {
        return { label: type, value: type };
      }),
    [comments]
  );

  // aliados
  const aliados = useMemo(
    () =>
      [...new Set(comments.map((item) => item.MDT_AC))].map((type) => {
        return { label: type, value: type };
      }),

    [comments]
  );

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // Custom filter

    if (dateFilter === "Personalizado") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ REGISTRADO }) =>
            new Date(REGISTRADO) > value[0] && new Date(REGISTRADO) < value[1]
        );
      computedComments = filterByDate(computedComments);
    }

    // Filter by year

    if (dateFilter === "Este año") {
      var p = new Date().getFullYear();
      const filterByDate = (arr, yearFilter) =>
        arr.filter(
          ({ REGISTRADO }) => new Date(REGISTRADO).getFullYear() === yearFilter
        );
      computedComments = filterByDate(computedComments, p);
    }

    // Filter by Month

    if (dateFilter === "Este mes") {
      var filter_year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;

      const filterByDate = (arr, yearFilter, monthF) =>
        arr.filter(
          ({ REGISTRADO }) =>
            new Date(REGISTRADO).getMonth() + 1 === yearFilter &&
            new Date(REGISTRADO).getFullYear() === monthF
        );

      computedComments = filterByDate(computedComments, month, filter_year);
    }

    // Filter by Week
    if (dateFilter === "Esta semana") {
      const getStartOfWeek = (date) => {
        date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - date.getDay());
        return date;
      };

      const getEndOfWeek = (date) => {
        date = getStartOfWeek(date);
        date.setDate(date.getDate() + 6);
        return date;
      };

      var inicio = getStartOfWeek();
      var final = getEndOfWeek();

      const filterByDate = (arr) =>
        arr.filter(
          ({ REGISTRADO }) =>
            new Date(REGISTRADO) > inicio && new Date(REGISTRADO) < final
        );
      computedComments = filterByDate(computedComments);
    }

    // Filter (Today)
    if (dateFilter === "Hoy") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ REGISTRADO }) =>
            new Date(REGISTRADO).setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0)
        );

      computedComments = filterByDate(computedComments);
    }

    // Filter (Yesterday)
    if (dateFilter === "Ayer") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ REGISTRADO }) =>
            new Date(REGISTRADO).setHours(0, 0, 0, 0) ===
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 1
            ).setHours(0, 0, 0, 0)
        );

      computedComments = filterByDate(computedComments);
    }

    // check how many elements aren't empty inside multipleSearch
    var qty = 0;
    for (var key in multipleSearch) {
      if (multipleSearch[key] !== "") {
        qty = qty + 1;
      }
      setQtyNN(qty);
    }

    // Global search
    if (search !== "" && search !== null) {
      computedComments = computedComments.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    // search based on multiple categories

    if (
      qtyNN >= 1 &&
      Object.values(multipleSearch).every((x) => x === null || x === "") ===
        false
    ) {
      let check = {
        ESTATUS_MDT: `${multipleSearch.estatus}`,
        MDT: `${multipleSearch.MDT}`,
        MDT_AC: `${multipleSearch.aliado}`,
        PLAN_SOLICITADO: `${multipleSearch.plan}`,
        NODO: `${multipleSearch.nodo}`,
        NAP: `${multipleSearch.NAP}`,
      };

      // remove empty elements
      check = Object.fromEntries(
        Object.entries(check).filter(([_, v]) => v !== "")
      );

      // check inside records
      computedComments = computedComments.filter((result) =>
        Object.entries(check).every(([key, val]) =>
          val !== "" ? result[key] === val : true
        )
      );
    }

    setExport(computedComments);
    setTotalItems(computedComments.length);

    //Sorting rows
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Slice current Page
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    qtyNN,
    multipleSearch,
    dateFilter,
    value,
  ]);

  const TableInformation = ({ info }) => {
    return (
      <>
        {info.map((comment, index) => (
          <tr
            key={index}
            className={`${
              checkedCheckboxes.some(
                (checkedCheckbox) => checkedCheckbox.ID === comment.ID
              ) === true
                ? "row-selected"
                : ""
            } `}
          >
            <td>
              <input
                key={`cb-${index}`}
                value={comment.ID}
                type="checkbox"
                checked={checkedCheckboxes.some(
                  (checkedCheckbox) => checkedCheckbox.ID === comment.ID
                )}
                onChange={() => handleCheckboxChange(comment)}
              />
            </td>

            <td>
              <div>
                {comment.REASIGNADO === "SI" && (
                  <div
                    style={{
                      width: "100%",
                      marginRight: 5,
                      marginBottom: 5,
                    }}
                  >
                    <div
                      className="tooltip1"
                      style={{
                        cursor: comment.PAUSADA !== "" ? "pointer" : "text",
                      }}
                    >
                      Reasignada
                      {comment.PAUSADA !== "" && (
                        <span class="tooltiptext">{comment.PAUSADA}</span>
                      )}
                    </div>
                  </div>
                )}
                {comment.REGISTRADO.replace("T", " ")}
              </div>
            </td>
            <td>{comment.CI_RIF}</td>
            <td>{comment.NOMBRE}</td>
            <td>{comment.TELEFONO}</td>
            <td>{comment.CORREO}</td>
            <td>
              <div
                className={`banner large ${
                  comment.MDT_AC === "ZONA AIRTEK" ? "s-a" : ""
                }`}
              >
                {comment.MDT_AC}
              </div>
            </td>

            <td>
              <button
                className="btn btn-coords align-items-center"
                onClick={(e) => {
                  if (comment.UBICACION_CP !== null) {
                    navigator.clipboard.writeText(comment.UBICACION_CP);
                    setCords({
                      lat: comment.UBICACION_CP.split(",")[0],

                      lng: comment.UBICACION_CP.split(",")[1],
                    });
                    setToast(true);

                    setTimeout(() => {
                      setToast(false);
                    }, 1800);
                  }
                  e.preventDefault();
                }}

              >
                <img src={map_pin} alt="ícono marcador" className="map-pin" />
              </button>
            </td>
            <td>{comment.MDT}</td>
            <td>
              <Status title={comment.ESTATUS_MDT} />
            </td>
            <td>
              <button
                onClick={() =>
                  SetModalInfo(
                    comment.PLAN_SOLICITADO,
                    comment.NODO,
                    comment.NAP,
                    comment.DIRECCION
                  )
                }
                className="btn btn-vm"
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div>
      <div>
        <div className="mb-3">
          <CardTabs location={coords} isLoadingC={isLoading} />
        </div>

        <>{asignar_a.length !== 0 && <AsignarCP data={asignar_a} />}</>

        {isLoading === true ? (
          <Loader />
        ) : (
          <>
            {comments && comments.length >= 1 ? (
              <div className="col mb-3 col-12 text-center">
                <div className="row">
                  <div className="col-md-12 mt-1 mb-4 d-flex flex-row justify-content-between align-items-center  pe-4">
                    <div className="d-flex w-100 justify-content-between filters-col">
                      <div
                        className="d-flex me-2 search-bar-tableb"
                        style={{ width: "286px" }}
                      >
                        <div className="dropdown me-1 ">
                          <button
                            className="btn btn-primary dropdown-toggle download-btn ps-1 pe-1 pt-1 pb-1"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={arrow_down} alt="ícono flecha" />
                          </button>
                          <ul
                            className="dropdown-menu drpdwn-export"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li className="pdf-list">
                              <ExportExcel data={downloadExcel} rep_t={1} />
                            </li>

                            <li className="pdf-list">
                              <ExportPDF data={downloadExcel} rep_t={1} />
                            </li>
                          </ul>
                        </div>

                        <Search
                          onSearch={(value) => {
                            setSearch(value);
                            setCurrentPage(1);
                          }}
                          placeholder={"Buscar Por Nombre del Aliado"}
                        />
                      </div>

                      <div className="d-flex drpdwn-filters">
                        <div className="d-flex align-items-center me-3 drpd select-f">
                          <div className="btn-group">
                            <button
                              className="btn btn-light dropdown-toggle"
                              type="button"
                              id="dropdownMenuClickableInside"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                            >
                              Seleccionar Fecha
                              <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                className="css-tj5bde-Svg ms-2"
                              >
                                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                              </svg>
                            </button>
                            <ul
                              className="dropdown-menu pe-2 ps-2"
                              aria-labelledby="dropdownMenuClickableInside"
                            >
                              <DateSelector
                                onSearch={(value) => {
                                  setdateFilter(value);
                                  setCurrentPage(1);
                                }}
                                op={"2"}
                              />

                              {dateFilter === "Personalizado" && (
                                <DateRangePicker
                                  calendarAriaLabel="Toggle calendar"
                                  clearAriaLabel="Clear value"
                                  dayAriaLabel="Day"
                                  monthAriaLabel="Month"
                                  nativeInputAriaLabel="Date"
                                  clearIcon={null}
                                  yearAriaLabel="Year"
                                  format={"MM-dd-y"}
                                  onChange={onChange}
                                  value={value}
                                  rangeDivider={"al"}
                                />
                              )}
                            </ul>
                          </div>
                        </div>

                        <div className="filters2 d-flex ">
                          <div className="d-flex  select-f">
                            <Select1
                              onSearch={(value) => {
                                setMultipleSearch({
                                  ...multipleSearch,
                                  nodo: value,
                                });
                                setCurrentPage(1);
                              }}
                              data={test}
                              placeholder={"OLT"}
                            />
                          </div>

                          <div className="d-flex flex-column select-f">
                            <Select1
                              onSearch={(value) => {
                                setMultipleSearch({
                                  ...multipleSearch,
                                  aliado: value,
                                });
                                setCurrentPage(1);
                              }}
                              data={aliados}
                              qty={qtyNN}
                              placeholder={"Aliado"}
                            />
                          </div>

                          <div className="d-flex flex-column select-f">
                            <Select1
                              onSearch={(value) => {
                                setMultipleSearch({
                                  ...multipleSearch,
                                  plan: value,
                                });
                                setCurrentPage(1);
                              }}
                              data={plan_1}
                              placeholder={"Plan"}
                            />
                          </div>

                          <div className="d-flex flex-column select-f">
                            <Select1
                              onSearch={(value) => {
                                setMultipleSearch({
                                  ...multipleSearch,
                                  MDT: value,
                                });
                                setCurrentPage(1);
                              }}
                              data={mdt}
                              placeholder={"MDT"}
                            />
                          </div>

                          <div className="d-flex flex-column select-f">
                            <Select1
                              onSearch={(value) => {
                                setMultipleSearch({
                                  ...multipleSearch,
                                  estatus: value,
                                });

                                setCurrentPage(1);
                              }}
                              data={options}
                              placeholder={"Estatus"}
                            />
                          </div>

                          <div className="d-flex flex-column select-f">
                            <Select1
                              onSearch={(value) => {
                                setMultipleSearch({
                                  ...multipleSearch,
                                  NAP: value,
                                });
                                setCurrentPage(1);
                              }}
                              data={nap_1}
                              placeholder={"NAP"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-b table-bordered">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                      id={"selectAll"}
                      type={"checkbox"}
                    />

                    <tbody>
                      <TableInformation info={commentsData} />
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row total-tb">
                    <div className="d-flex">
                      <p className="mb-0 total-reg">
                        {" "}
                        Registros Seleccionados:{" "}
                      </p>
                      <span className="ms-1 total-records">
                        {checkedCheckboxes.length}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex flex-row total-tb pe-2">
                    <p className="mb-0 total-reg"> Total Registros: </p>
                    <span className="ms-1 total-records">
                      {downloadExcel.length}
                    </span>
                  </div>
                </div>

                <div className="btn-pagination d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <button
                      disabled={checkedCheckboxes.length < 1 ? true : ""}
                      className={`btn btn-a me-2 ${
                        checkedCheckboxes.length >= 1
                          ? "btn-asignar"
                          : "btn-disabled"
                      }`}
                      onClick={() => {
                        asignar();
                      }}
                    >
                      Asignar
                    </button>

                    <button
                      disabled={checkedCheckboxes.length !== 1 ? true : ""}
                      className={`btn btn-a me-2 ${
                        checkedCheckboxes.length === 1
                          ? "btn-asignar bg-secondary"
                          : "btn-disabled"
                      }`}
                      onClick={() => {
                        pausar();
                      }}
                    >
                      Pausar
                    </button>

                    <button
                      disabled={checkedCheckboxes.length !== 1 ? true : ""}
                      className={`btn btn-a me-2 ${
                        checkedCheckboxes.length === 1
                          ? "btn-asignar bg-danger"
                          : "btn-disabled"
                      }`}
                      onClick={() => {
                        cancelar();
                      }}
                    >
                      Cancelar
                    </button>
                  </div>

                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    totalItemsCount={totalItems}
                    disabledClass={"disabled-page"}
                  
                    onChange={(page) => setCurrentPage(page)}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            ) : (
              <div className="h-100 d-flex flex-column pt-3 pb-3">
                <img
                  src={emptyData}
                  alt="Data no disponible"
                  className="m-auto"
                />
                <p
                  className="text-center"
                  style={{
                    color: "#AEB8C2",
                    fontWeight: "bold",
                  }}
                >
                  No se han encontrado registros.
                </p>
              </div>
            )}
          </>
        )}
      </div>

      <Modal1
        open={modal}
        modalType={modalType}
        close={() => Open()}
        uInfo={modalInfo}
      />

      <ModalStep
        open={modalStepM}
        close={() => OpenMS()}
        cod={checkedCheckboxes}
        id={step}
      />

      <ModalAsignar
        open={modalAC}
        asignar={"CP"}
        close={() => OpenAC()}
        qty={checkedCheckboxes}
      />

      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 11 }}>
        <div
          id="liveToast"
          className={`toast ${toast ? "show" : "hide"}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style={{
            borderRadius: "10px",
            backgroundColor: "#0066ff",
            color: "#fff",
          }}
        >
          <div className="d-flex justify-content-between p-2">
            <p className="mb-0">Coordenadas copiadas exitosamente.</p>

            <button
              type="button"
              className="btn-close btn-close-white"
              style={{ color: "#fff", opacity: "1" }}
              onClick={() => setToast(false)}
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableB;
