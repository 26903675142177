import React, { useEffect, useState, useMemo } from "react";
import TableHeader from "../../components/shared/TableHeader/TableHeader";
import Pagination from "react-js-pagination";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "./CambioP.css";
import Select1 from "../../components/shared/Select/Select";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation } from "swiper";
import "../../components/Card/Card.css";
import "./CambioP.css";
import { v4 as uuidv4 } from "uuid";
import ModalSlider from "../../components/Modal/ModalSlider/ModalSlider";
import axios from "axios";
import Cookies from "universal-cookie";
import { getIndicadores } from "../../utils/functions/getIndicadores";
import { updZonaAirtek } from "../../utils/functions/updZonaAirtek";
import Loader from "../../components/Loaders/Loader";
import emptyData from "../../assets/img/empty.png";

const CambioP = () => {
  SwiperCore.use([Navigation]);

  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 7;
  const cookies = new Cookies();
 // const [totalItems, settotal] = useState(0);
  const [codgrupo, setcodgrupo] = useState("0");
  const [modalInfo, setInfo] = useState({
    Codigo_Cliente: "",
    Direccion: "",
    Pon: "",
    Fecha_de_Generacion: "",
    Equipo_Cliente: "",
    Nodo: "",
    Ubicacion: "",
    Olt: "",
    Codigo_MDT: "",
    Nap: "",
    Vlan: "",
    Board: "",
    Port: "",
    Ont_Id: "",
    Puerto_Nodo: "",
    Pon_Reemplazado: "",
  });
  const [tkn] = useState(cookies.get("tkn"));
  const [modalType, setmodalType] = useState(0);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [value, onChange] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [total_Records, settotal_Records] = useState(0);
  const [grupoServicio, setgrupoServicio] = useState([]);
  const [CambioP_Mes, setCambioP_Mes] = useState([]);
  const [CambioP_Dia, setCambioP_Dia] = useState([]);
  const current = new Date();
  const month_n = current.toLocaleString("default", { month: "long" });

  // modal
  const Open = () => {
    setModal(!modal);
  };

  const MINUTE_MS = 90000;

  useEffect(() => {
    var fechas_format = [];

    if (value !== null) {
      value.forEach((element) => {
        var _date = element.getDate();
        _date = `${_date}`.padStart(2, "0");

        var _month = element.getMonth() + 1;
        _month = `${_month}`.padStart(2, "0");

        var _year = element.getFullYear();

        var fechaf = _date + "-" + _month + "-" + _year;
        fechaf = fechaf.replace(/-/g, "/");

        fechas_format.push(fechaf);
      });
    }

    async function getData() {
      setIsLoading(true);

      var base_url = `${process.env.REACT_APP_API}${
        process.env.REACT_APP_CAMBIO_DE_PLAN
      }page=${currentPage}&records=${ITEMS_PER_PAGE}${
        codgrupo !== "undefined" && codgrupo !== "0"
          ? `&cod_grupo=${codgrupo}`
          : ""
      }${
        fechas_format[0] !== undefined ? `&fechaInicio=${fechas_format[0]}` : ""
      }${
        fechas_format[1] !== undefined ? `&fechaFin=${fechas_format[1]}` : ""
      }`;

      axios
        .get(base_url, {
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        })
        .then(async (res) => {
          var instalaciones = await updZonaAirtek(res.data);
         // settotal(instalaciones.total_pages);
          setError(false);
          settotal_Records(instalaciones.total_records);
          setIsLoading(false);
          setComments(instalaciones.records);
        })
        .catch((error) => {
          setError(true);
          setIsLoading(false);
          console.log(error);
        });
    }

    async function getCP() {
      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_CAMBIO_DE_PLAN_MES}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then(async (res) => {
          var cdp_mes = await getIndicadores(res.data);
          setCambioP_Mes(cdp_mes);
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_CAMBIO_DE_PLAN_DIA}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then(async (res) => {
          var cdp_dia = await getIndicadores(res.data);
          setCambioP_Dia(cdp_dia);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    getCP();
    getData();

    const interval = setInterval(() => {
      getCP();
      getData();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [currentPage, codgrupo, value, tkn]);

  useEffect(() => {
    const getGroup = () => {
      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_GRUPOS_LIST}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then((res) => {
          setgrupoServicio(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getGroup();
  }, [tkn]);

  const headers = [
    { name: "Sucursal", field: "Sucursal", sortable: false },
    { name: "Nombre", field: "Nombre", sortable: false },
    { name: "Plan Anterior", field: "PlanAnterior", sortable: false },
    { name: "Plan Actual", field: "Plan", sortable: false },
    { name: "MDT", field: "NombreMdt", sortable: false },
    { name: "Aliado", field: "Ac", sortable: false },
    { name: "Tecnología", field: "Tecnologia", sortable: false },
    { name: "Fecha de Generación", field: "FechaGeneracion", sortable: false },
    { name: "Ver más", field: "", sortable: false },
  ];

  const grupo_Servicio = useMemo(
    () => [
      ...new Set(
        grupoServicio.map((item) => {
          return { label: item.NombreGrupo, value: item.CodigoGrupo };
        })
      ),
    ],

    [grupoServicio]
  );

  const SetModalInfo = (codigo) => {
    var info_ad = {
      Codigo_Cliente: codigo.CodigoCliente,
      Direccion: codigo.Direccion,
      Pon: codigo.Pon,
      Equipo_Cliente: codigo.EquipoCliente,
      nodo: codigo.Nodo,
      Ubicacion: codigo.Ubicacion,
      Olt: codigo.Olt,
      Codigo_MDT: codigo.CodigoMdt,
      Nap: codigo.Nap,
      Vlan: codigo.Vlan,
      Board: codigo.Board,
      Port: codigo.Port,
      Ont_Id: codigo.OntId,
      Puerto_Nodo: codigo.PuertoNodo,
      Pon_Reemplazado: codigo.PonReemplazado,
    };

    info_ad = Object.fromEntries(
      Object.entries(info_ad).filter(([_, v]) => v !== "")
    );

    setInfo(info_ad);
    setmodalType(6);
    Open();
  };

  const TableInformation = ({ info }) => {
    return (
      <>
        {info.map((comment, index) => (
          <tr key={index}>
            <td>{comment.Sucursal}</td>
            <td>{comment.Nombre}</td>
            <td>{comment.PlanAnterior}</td>
            <td>{comment.Plan}</td>
            <td>{comment.NombreMdt}</td>
            <td>{comment.Ac}</td>
            <td>{comment.Tecnologia}</td>
            <td>{comment.FechaGeneracion}</td>
            <td>
              <button
                className="btn btn-light btn-vmas border-0"
                onClick={() => SetModalInfo(comment)}
              >
                <i
                  className="fa-solid fa-chevron-right"
                  style={{ fontSize: "14px" }}
                ></i>
              </button>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="mb-4">
      <div className="d-flex w-100 mb-4">
        <p className="mx-auto asignacion-title mb-0">Cambio de Plan</p>
      </div>

      <div>
        <Swiper
          navigation={true}
          slidesPerView={5}
          spaceBetween={20}
          slidesOffsetBefore={10}
          slidesOffsetAfter={30}
          pagination={{
            clickable: true,
          }}
        >
          {CambioP_Mes &&
            CambioP_Mes.map((comment) => (
              <SwiperSlide key={uuidv4()}>
                <div className="card-i h-100 w-100 me-3 text-dark p-3">
                  <div className="my-auto">
                    <p className="title-det">{comment.grupo}</p>

                    <div className="d-flex align-items-center">
                      <p className="total-number mb-0 me-2">
                        {comment.total_grupo}
                      </p>

                      <span className="card-month">
                        Mes {month_n.charAt(0).toUpperCase() + month_n.slice(1)}{" "}
                        {current.getFullYear()}
                      </span>
                    </div>

                    {CambioP_Dia.map((inst) => (
                      <React.Fragment key={uuidv4()}>
                        {inst.grupo === comment.grupo && (
                          <div className="d-flex align-items-center">
                            <p className="total-number mb-0 me-3">
                              {inst.total_grupo}
                            </p>

                            <span className="card-month text-decoration-underline fw-bold">
                              Día
                            </span>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </SwiperSlide>
            ))}

          {CambioP_Mes &&
            CambioP_Mes.map((comment) => (
              <React.Fragment key={uuidv4()}>
                {comment &&
                  comment.detalle.map((p) => (
                    <SwiperSlide key={uuidv4()}>
                      <div className="card-i h-100 w-100 me-3 text-dark p-3">
                        <div className="my-auto">
                          <p className="title-det">{p.Plan}</p>

                          <div className="d-flex align-items-center">
                            <p className="total-number mb-0 me-3">
                              {p.Cantidad}
                            </p>
                            <span className="card-month">
                              Mes{" "}
                              {month_n.charAt(0).toUpperCase() +
                                month_n.slice(1)}{" "}
                              {current.getFullYear()}
                            </span>
                          </div>
                        </div>

                        {CambioP_Dia.map((inst) => (
                          <React.Fragment key={uuidv4()}>
                            {inst &&
                              inst.detalle.map(
                                (d) =>
                                  d.Plan === p.Plan && (
                                    <React.Fragment key={uuidv4()}>
                                      <div className="d-flex align-items-center">
                                        <p className="total-number mb-0 me-3">
                                          {d.Cantidad}
                                        </p>

                                        <span className="card-month text-decoration-underline fw-bold">
                                          Día
                                        </span>
                                      </div>
                                    </React.Fragment>
                                  )
                              )}
                          </React.Fragment>
                        ))}
                      </div>
                    </SwiperSlide>
                  ))}
              </React.Fragment>
            ))}
        </Swiper>
      </div>

      <div className="w-100 d-flex align-items-end mt-5 mb-2">
        <div className="d-flex w-100 ms-auto justify-content-end">
          <div className="me-4">
            <Select1
              onSearch={(value) => {
                setcodgrupo(value);
              }}
              data={grupo_Servicio}
              placeholder={"Seleccionar Grupo"}
              zeroValue={true}
            />
          </div>

          <div className="d-flex align-items-center me-3 drpd select-f">
            <div className="btn-group">
              <button
                className="btn btn-light dropdown-toggle"
                type="button"
                id="dropdownMenuClickableInside"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                Seleccionar Fecha
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  className="css-tj5bde-Svg ms-2"
                >
                  <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
              </button>
              <ul
                className="dropdown-menu pe-2 ps-2 w-auto"
                aria-labelledby="dropdownMenuClickableInside"
              >
                <DateRangePicker
                  onChange={onChange}
                  value={value}
                  maxDate={new Date()}
                  format={"dd-MM-y"}
                  rangeDivider={"al"}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>

      {isLoading === true ? (
        <div className="w-100 h-100 d-flex pt-5">
          <div className="mx-auto h-100">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          {!error ? (
            <div>
              <div className="table-responsive mt-3">
                <table className="table table-b table-mdt table-bordered">
                  <TableHeader headers={headers} id={"selectAll"} />

                  <tbody>
                    <TableInformation info={comments} />
                  </tbody>
                </table>
              </div>

              <div className="row">
                <p className="mb-2 total-reg total-reg-ac">
                  Total Registros: <span>{total_Records}</span>
                </p>
              </div>

              <div className="d-flex w-100">
                <div className="pag-reportes">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    totalItemsCount={total_Records}
                    disabledClass={"disabled-page"} 
                    onChange={(page) => setCurrentPage(page)}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>

              <ModalSlider
                open={modal}
                modalType={modalType}
                close={() => Open()}
                uInfo={modalInfo}
              />
            </div>
          ) : (
            <>
                                <div className="w-100 d-flex h-100">
                      <div className="mx-auto my-auto mt-5 text-center">
                        <img
                          src={emptyData}
                          alt="Data no disponible"
                          className="m-auto"
                        />

                        <p
                          className="text-center"
                          style={{
                            color: "#AEB8C2",
                            fontWeight: "bold",
                          }}
                        >
                          No se han encontrado registros.
                        </p>
                      </div>
                    </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CambioP;
