import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ data, rep_t }) => {
  return (
    <div>
      <div>
        <ExcelFile
          element={<button className="dropdown-item">Excel</button>}
          filename={
            rep_t === 1
              ? "Reporte Clientes Potenciales"
              : rep_t === 2
              ? "Reporte Asignación MDT"
              : rep_t === 3
              ? "Reporte Aliados Comerciales"
              : "Reporte Clientes Asignados"
          }
        >
          {(() => {
            switch (rep_t) {
              case 1:
                return (
                  <ExcelSheet data={data} name="Reporte">
                    <ExcelColumn
                      label="Registrado"
                      value="REGISTRADO"
                      width={190}
                    />
                    <ExcelColumn label="Nombre" value="NOMBRE" />
                    <ExcelColumn label="C.I / RIF" value="CI_RIF" />
                    <ExcelColumn label="Correo" value="CORREO" />
                    <ExcelColumn label="Teléfono" value="TELEFONO" />
                    <ExcelColumn label="Aliado Comercial" value="MDT_AC" />
                    <ExcelColumn
                      label="Plan Solicitado"
                      value="PLAN_SOLICITADO"
                    />
                    <ExcelColumn label="MDT" value="MDT" />
                    <ExcelColumn label="Estatus MDT" value="ESTATUS_MDT" />
                    <ExcelColumn label="NAP" value="NAP" />
                    <ExcelColumn label="Estatus NAP" value="ESTATUS_NAP" />
                    <ExcelColumn label="Distancia NAP" value="DISTANCIA_NAP" />
                    <ExcelColumn label="OLT" value="NODO" />
                    <ExcelColumn label="COORDENADAS" value="UBICACION_CP" />

                  </ExcelSheet>
                );

              case 2:
                return (
                  <ExcelSheet data={data} name="Reporte">
                    <ExcelColumn
                      label="Fecha de Asignación"
                      value="FechaInstalacion"
                    />

                    <ExcelColumn
                      label="Aliado Comercial"
                      value="Ac"
                      width={190}
                    />

                    <ExcelColumn
                      label="Aliado Comercial Reasignado"
                      value="Reasignado_Ac"
                    />

                    <ExcelColumn
                      label="Última Actualización"
                      value="FechaReasignacion"
                    />

                    <ExcelColumn
                      label="Realizado Por"
                      value="UserReasignacion"
                    />

                    <ExcelColumn label="OLT" value="Nodo" />
                    <ExcelColumn label="MDT" value="Nombre_mdt" />

                    <ExcelColumn label="Estatus" value="Estado" />
                  </ExcelSheet>
                );

              case 3:
                return (
                  <ExcelSheet data={data} name="Reporte">
                    <ExcelColumn label="Aliado Comercial" value="descripcion" />
                    <ExcelColumn label="CI / RIF" value="rif" />
                    <ExcelColumn label="Representante" value="representante" />
                    <ExcelColumn label="Instagram" value="instagram" />
                    <ExcelColumn label="Correo" value="email" />
                    <ExcelColumn label="Teléfono" value="telefono" />
                    <ExcelColumn label="Dirección" value="direccion" />
                  </ExcelSheet>
                );

              case 4:
                return (
                  <ExcelSheet data={data} name="Reporte">
                    <ExcelColumn label="Registrado" value="REGISTRADO" />
                    <ExcelColumn label="Nombre" value="NOMBRE" />
                    <ExcelColumn label="CI / RIF" value="CI_RIF" />
                    <ExcelColumn label="Teléfono" value="TELEFONO" />
                    <ExcelColumn label="Correo" value="CORREO" />

                    <ExcelColumn
                      label="Plan Solicitado"
                      value="PLAN_SOLICITADO"
                    />
                    <ExcelColumn label="MDT" value="MDT" />
                    <ExcelColumn label="NAP" value="NAP" />
                    <ExcelColumn label="Estatus NAP" value="ESTATUS_NAP" />
                    <ExcelColumn
                      label="Aliado Asignado"
                      value="CLIENTE_ASIG_AC"
                    />
                    <ExcelColumn label="Promotor" value="PROMOTOR" />
                    <ExcelColumn
                      label="Fecha de Asignación"
                      value="FCH_ASIG_CLIENTE_AC"
                    />
                  </ExcelSheet>
                );

              default:
                <ExcelSheet data={data} name="Reporte">
                  <ExcelColumn
                    label="Registrado"
                    value="REGISTRADO"
                    width={190}
                  />
                  <ExcelColumn label="Nombre" value="NOMBRE" />
                  <ExcelColumn label="C.I / RIF" value="CI_RIF" />
                  <ExcelColumn label="Correo" value="CORREO" />
                  <ExcelColumn label="Teléfono" value="TELEFONO" />
                  <ExcelColumn label="Aliado Comercial" value="MDT_AC" />
                  <ExcelColumn
                    label="Plan Solicitado"
                    value="PLAN_SOLICITADO"
                  />
                  <ExcelColumn label="MDT" value="MDT" />
                  <ExcelColumn label="Estatus MDT" value="ESTATUS_MDT" />
                  <ExcelColumn label="NAP" value="NAP" />
                  <ExcelColumn label="Estatus NAP" value="ESTATUS_NAP" />
                  <ExcelColumn label="Distancia NAP" value="DISTANCIA_NAP" />
                  <ExcelColumn label="OLT" value="NODO" />
                </ExcelSheet>;
            }
          })()}
        </ExcelFile>
      </div>
    </div>
  );
};

export default ExportExcel;
