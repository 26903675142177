import React from "react";

const Input = ({
  fieldName,
  register,
  errors,
  placeHolder,
  isRequired,
  maximLength,
  minimLength,
  watch,
  isEmail,
  isPassword,
  isNumerical,
  matchField,
  isPhone,
}) => {
  return (
    <div className="field-name w-100">
      <input
        type={isPassword ? "password" : isPhone ? "tel" : "text"}
        className="form-control-ac border-secondary border-end rounded-pill"
        placeholder={placeHolder}
        {...register(fieldName, {
          required: {
            value: isRequired,
            message: `Este campo es requerido *.`,
          },

          validate: (val) => {
            if (matchField) {
              if (watch("Password") !== val) {
                return "Los campos 'Contraseña' y 'Confirmar Contraseña' no coinciden.";
              }
            }
          },

          pattern: {
            value: isEmail
              ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
              : isPassword
              ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
              : isPhone
              ? /^\d{11}$/
              : isNumerical
              ? /^[0-9]+$/
              : "",
            message: isEmail
              ? "Ingrese una dirección de correo electrónico válida(por ejemplo, airtek@airtek.com.ve)."
              : isPassword
              ? "La contraseña debe incluir al menos un número, un símbolo especial, una letra mayúscula y una letra minúscula."
              : isNumerical
              ? "Este campo solo permite caracteres numéricos [0-9]"
              : "",
          },

          maxLength: {
            value: maximLength,
            message: `Este campo debe tener como máximo ${maximLength} caracteres.`,
          },
          minLength: {
            value: minimLength,
            message: `Este campo debe tener como mínimo ${minimLength} caracteres.`,
          },
        })}
        onKeyDown={(e) => {
          if (isPhone) {
            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
            if (!allowedKeys.includes(e.key) && !/^[0-9]$/.test(e.key)) {
              e.preventDefault();
            }
          } 

          if (fieldName === "Nombre" || fieldName === "Apellido") {
            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', ' '];
            if (!allowedKeys.includes(e.key) && !/^[a-zA-Z]$/.test(e.key)) {
              e.preventDefault();
            }
          }
        }}
        maxLength={maximLength || 250}
      />

      <p className="lg-error mb-0">
        {
          //If error exists in the textField
          errors[fieldName] && errors[fieldName].message
        }
      </p>
    </div>
  );
};

export default Input;
